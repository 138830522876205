import { useSearchParams } from "react-router-dom";

const videoMap = {
    "1ksa": "https://vvoids.blob.core.windows.net/assets/sanctamaria/videos/1KSA.mp4",
    "1ksb": "https://vvoids.blob.core.windows.net/assets/sanctamaria/videos/1KSB.mp4",
    "2ks": "https://vvoids.blob.core.windows.net/assets/sanctamaria/videos/2KS.mp4",
    "3ks": "https://vvoids.blob.core.windows.net/assets/sanctamaria/videos/3KS.mp4",
    "1b": "https://vvoids.blob.core.windows.net/assets/sanctamaria/videos/1B.mp4",
    "2b": "https://vvoids.blob.core.windows.net/assets/sanctamaria/videos/2B.mp4",
    "3b": "https://vvoids.blob.core.windows.net/assets/sanctamaria/videos/3B.mp4",
    "4b": "https://vvoids.blob.core.windows.net/assets/sanctamaria/videos/4B.mp4",
    "5b": "https://vvoids.blob.core.windows.net/assets/sanctamaria/videos/5B.mp4",
    "6b": "https://vvoids.blob.core.windows.net/assets/sanctamaria/videos/6B.mp4",
};

export default function PageVideo(props) {
    const [searchParams] = useSearchParams();
    const videoSrc = videoMap[searchParams.get("v")] || null;

    return (!videoSrc ? <p>No video found</p> : <video className="w-screen h-screen" controls>
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
    </video>);
}