import { useTranslation } from "react-i18next";
import { SendOutlined, DownloadOutlined, CameraOutlined } from '@ant-design/icons';
import { Alert, Button, Image, Input, Layout, Space, Spin, Upload } from "antd";
import { Select } from 'antd';
import Logo from '../../assets/sprites/logos/SanctaLogo.png';
import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { DEFAULT, LANGUAGES, QueryCase, QueryId, ROUTES, ToastId } from '../../data/enums';
import ReactCountryFlag from "react-country-flag";
import LayoutOne from '../../assets/sprites/images/SanctaMaria/LayoutOne.png';
import LayoutTwo from '../../assets/sprites/images/SanctaMaria/LayoutTwo.png';
import LayoutThree from '../../assets/sprites/images/SanctaMaria/LayoutThree.png';
import Pattern1 from '../../assets/sprites/images/SanctaMaria/BoothBg1.png';
import Pattern2 from '../../assets/sprites/images/SanctaMaria/BoothBg2.png';
import Pattern3 from '../../assets/sprites/images/SanctaMaria/BoothBg3.png';
import Pattern4 from '../../assets/sprites/images/SanctaMaria/BoothBg4.png';
import Pattern5 from '../../assets/sprites/images/SanctaMaria/BoothBg5.png';
import ImgCrop from "antd-img-crop";
import { handleUploadPicture, notifyError, notifySuccess } from "../../data/extensions";
import { GetData, MutateData } from "../../data/axios";
import SanctaLogo from "../../assets/sprites/logos/SanctaLogo.png"
import VvoidsLogo from "../../assets/sprites/logos/logo256.png"
import * as htmlToImage from 'html-to-image';
import i18n from "i18next";

export default function Photobooth(props) {
    const { Header, Content } = Layout;
    const { t } = useTranslation();
    const [email, setEmail] = useState(DEFAULT.TEXT);
    const [layout, setLayout] = useState(0);
    const [design, setDesign] = useState(0);
    const [isEmailFalse, setIsEmailFalse] = useState(false);

    useEffect(() => { i18n.changeLanguage(LANGUAGES.NL); }, []);

    const actionGetBooth = GetData(QueryId.SelectPhotobooth, { email: email }, false, 0);

    const saveImage = () => {
        htmlToImage.toPng(document.getElementById("booth"), { quality: 0.95 })
            .then(function (dataUrl) {
                var link = document.createElement("a");
                link.download = "my-photobooth.png";
                link.href = dataUrl;
                link.click();
            });
    }

    return (
        <>
            <Layout className="h-screen">
                <Header className="px-4 z-50 h-16 text-md bg-vvoids-darker text-vvoids-white border-b-2 border-vvoids-white"><HeaderSancta initLanguage="nl" /></Header>
                <Layout className="bg-vvoids-dark">
                    <Content>
                        <div className="m-5 bg-vvoids-dark text-vvoids-white">
                            <Alert message={t("sancta_booth_apple")}
                                description={t("sancta_booth_warning")}
                                type="warning"
                                showIcon
                                closable
                                className="mb-3"
                            />
                            <p>{t("sancta_booth_intro_1")}</p>
                            <br />
                            <p>{t("sancta_booth_intro_2a")}</p>
                            <p>{t("sancta_booth_intro_2b")}</p>
                            <br />
                            <p>{t("sancta_booth_intro_3a")}</p>
                            <p>{t("sancta_booth_intro_3b")}</p>
                            <p>{t("sancta_booth_intro_3c")}</p>
                            <br />
                            <p>{t("sancta_booth_intro_4")}</p>
                            <br />
                            {actionGetBooth.isLoading ? <Spin size="large" /> : !actionGetBooth.isFalse ? null :
                                <Space direction="horizontal">
                                    <Input status={isEmailFalse ? "error" : ""} placeholder={t("placeholder_email")} required allowClear value={email} onChange={(event) => { setIsEmailFalse(false); setEmail(event.target.value.toLowerCase()); }} />
                                    <Button className="bg-vvoids-blue border-vvoids-blue py-0" onClick={() => { if (email.includes('@') && email.includes('.')) { setIsEmailFalse(false); actionGetBooth.refetch(); } else { setIsEmailFalse(true); } }}><SendOutlined /></Button>
                                </Space>
                            }
                        </div>
                        {!actionGetBooth.data ? null :
                            <div className="bg-vvoids-darker relative pb-3">
                                <div className="text-center">
                                    <div className="pt-5 m-5 text-xl text-vvoids-white">{t("sancta_booth_profile")}: {email}</div>
                                    <div className="m-5 text-xl text-vvoids-white">{t("sancta_booth_layout")}:</div>
                                </div>
                                <div className="flex justify-around p-2">
                                    <Button onClick={() => { setLayout(0) }} className="!w-20 !h-40 !p-0" type={layout === 0 ? "primary" : ""} icon={<img className="p-1" src={LayoutOne} alt="-" />} size={"large"} />
                                    <Button onClick={() => { setLayout(1) }} className="!w-20 !h-40 !p-0" type={layout === 1 ? "primary" : ""} icon={<img className="p-1" src={LayoutTwo} alt="-" />} size={"large"} />
                                    <Button onClick={() => { setLayout(2) }} className="!w-20 !h-40 !p-0" type={layout === 2 ? "primary" : ""} icon={<img className="p-1" src={LayoutThree} alt="-" />} size={"large"} />
                                </div>
                                <div className="text-center">
                                    <div className="m-5 text-xl text-vvoids-white">{t("sancta_booth_pattern")}:</div>
                                </div>
                                <div className="flex gap-3 flex-wrap justify-around p-2">
                                    <Button onClick={() => { setDesign(1) }} className="!w-20 !h-[142px] !p-0 !border-none" type={design === 1 ? "primary" : ""} icon={<img className="rounded-lg" src={Pattern2} alt="-" />} size={"large"} />
                                    <Button onClick={() => { setDesign(2) }} className="!w-20 !h-[142px] !p-0 !border-none" type={design === 2 ? "primary" : ""} icon={<img className="rounded-lg" src={Pattern3} alt="-" />} size={"large"} />
                                    <Button onClick={() => { setDesign(4) }} className="!w-20 !h-[142px] !p-0 !border-none" type={design === 4 ? "primary" : ""} icon={<img className="rounded-lg" src={Pattern5} alt="-" />} size={"large"} />
                                    <Button onClick={() => { setDesign(0) }} className="!w-20 !h-[142px] !p-0 !border-none" type={design === 0 ? "primary" : ""} icon={<img className="rounded-lg" src={Pattern1} alt="-" />} size={"large"} />
                                    <Button onClick={() => { setDesign(3) }} className="!w-20 !h-[142px] !p-0 !border-none" type={design === 3 ? "primary" : ""} icon={<img className="rounded-lg" src={Pattern4} alt="-" />} size={"large"} />
                                </div>
                                <div className="flex justify-center mt-5">
                                    <Button
                                        disabled={!actionGetBooth.data?.photoUrl1 || !actionGetBooth.data?.photoUrl2 || !actionGetBooth.data?.photoUrl3 || !actionGetBooth.data?.photoUrl4 || !actionGetBooth.data?.photoUrl5}
                                        className="bg-vvoids-blue w-[75vw] border-vvoids-blue py-0 disabled:bg-red-900"
                                        onClick={() => { saveImage(); }}>{t("sancta_booth_download")} <DownloadOutlined /></Button>
                                </div>
                                <div className="my-7 max-w-xl mx-auto">
                                    <div id="booth" className="flex max-w-xl justify-center">
                                        <div className="flex flex-col max-w-xl">
                                            <div className={`relative bg-vvoids-grey rounded-t-3xl max-w-xl layout ${design === 0
                                                ? "boothPatternOne"
                                                : design === 1
                                                    ? "boothPatternTwo"
                                                    : design === 2
                                                        ? "boothPatternThree"
                                                        : design === 3
                                                            ? "boothPatternFour"
                                                            : "boothPatternFive"
                                                }`}>
                                                <div className={`layout-${layout}`}>
                                                    <Picture refetch={actionGetBooth.refetch} maxSize={99} maxWidth={375} maxHeight={375} aspect={1} classNameOverride={"ant-upload-photobooth-1 ant-upload-photobooth"} boothId={actionGetBooth.data?.sanctaBoothId} pictureIndex={1} picture={actionGetBooth.data?.photoUrl1} />
                                                    <Picture refetch={actionGetBooth.refetch} maxSize={99} maxWidth={375} maxHeight={667} aspect={0.5622} classNameOverride={"ant-upload-photobooth-2 ant-upload-photobooth"} boothId={actionGetBooth.data?.sanctaBoothId} pictureIndex={2} picture={actionGetBooth.data?.photoUrl2} />
                                                    <Picture refetch={actionGetBooth.refetch} maxSize={99} maxWidth={375} maxHeight={667} aspect={0.5622} classNameOverride={"ant-upload-photobooth-3 ant-upload-photobooth"} boothId={actionGetBooth.data?.sanctaBoothId} pictureIndex={3} picture={actionGetBooth.data?.photoUrl3} />
                                                    <Picture refetch={actionGetBooth.refetch} maxSize={99} maxWidth={375} maxHeight={375} aspect={1} classNameOverride={"ant-upload-photobooth-4 ant-upload-photobooth"} boothId={actionGetBooth.data?.sanctaBoothId} pictureIndex={4} picture={actionGetBooth.data?.photoUrl4} />
                                                    <Picture refetch={actionGetBooth.refetch} maxSize={99} maxWidth={800} maxHeight={350} aspect={2.2857} classNameOverride={"ant-upload-photobooth-5 ant-upload-photobooth"} boothId={actionGetBooth.data?.sanctaBoothId} pictureIndex={5} picture={actionGetBooth.data?.photoUrl5} />
                                                </div>
                                            </div>
                                            <div className="flex justify-between px-5 py-3 gap-3 max-w-xl bg-[#121212] text-vvoids-white rounded-b-3xl">
                                                <Image rootClassName="flex flex-col justify-center" preview={false} src={SanctaLogo} width={40} />
                                                <div className="text-center flex-grow w-min my-auto font-semibold text-lg">
                                                    {t("sancta_booth_collage_footer")}
                                                </div>
                                                <Image rootClassName="flex flex-col justify-center" preview={false} src={VvoidsLogo} width={40} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </Content>
                </Layout>
            </Layout>
        </>
    );
}

function Picture(props) {
    const { t } = useTranslation();

    const [picture, setPicture] = useState(props.picture);

    const updatePicture = MutateData(QueryId.UpdatePhotoboothPhoto,
        () => { }, [
        { case: QueryCase.Success, onCase: () => { notifySuccess(ToastId.ActionSuccess); props.refetch(); } },
        { case: QueryCase.Error, onCase: () => { setPicture(props.picture); notifyError(ToastId.ActionError) } }
    ]);

    const onFinish = (file) => {
        let properties = {};
        properties.url = DEFAULT.STRINGEMPTY;

        if (file) {
            handleUploadPicture(file, props.maxSize, props.maxWidth, props.maxHeight, "sanctamaria/photobooth/pictures").then((result) => {
                properties.boothId = props.boothId
                properties.url = result;
                properties.urlIndex = props.pictureIndex;
                setPicture(result);
                updatePicture.mutate(properties);
            }).catch(() => {
                setPicture(picture);
                notifyError(ToastId.ActionError);
            });
        }
        else {
            notifyError(ToastId.ActionError);
        }
    };

    return (<div className="flex">
        <ImgCrop quality={1} fillColor="transparant" modalTitle={<><p className="mb-2">{t("edit_picture")}</p></>} resetText={t("reset")} modalOk={t("confirm")} modalCancel={t("cancel")} zoomSlider aspect={props.aspect} showReset showGrid>
            <Upload capture={true} className={props.classNameOverride} showUploadList={{ showPreviewIcon: false }} fileList={[]} maxCount={1} accept="image/jpeg,image/png" beforeUpload={(file) => { onFinish(file); return false; }} listType="picture-card">
                {picture ? <Image preview={false} className="rounded-lg boxshadow" src={picture} /> : <CameraOutlined className="text-5xl text-vvoids-white" />}
            </Upload>
        </ImgCrop>
    </div>);
}

function HeaderSancta(props) {
    const { t, i18n } = useTranslation();
    const [languageValue, setLanguageValue] = useState(t(`language_${props.initLanguage}`));
    const [menuLanguagesOpen, setMenuLanguagesOpen] = useState(false);
    const handleChange = (value) => {
        i18n.changeLanguage(value);
        setLanguageValue(value);
    };

    useEffect(() => {
        i18n.changeLanguage(props.initLanguage ?? i18n.language);
        setLanguageValue(props.initLanguage ?? i18n.language);
    }, [i18n, props.initLanguage]);

    return (
        <div className='flex justify-between align-center h-full'>
            <Link to={ROUTES.SANCTA} className="invert_hover">
                <div className='flex h-full'>
                    <img className='h-4/5 m-auto max-h-[70px]' src={Logo} alt={t("sancta_maria")} />
                    <p className="font-bold my-auto pl-2">{t("sancta_maria").toUpperCase()}</p>
                </div>
            </Link>
            <div className="flex gap-4 my-auto">
                <Select
                    defaultValue={LANGUAGES.NL}
                    onChange={handleChange}
                    onBlur={() => { setMenuLanguagesOpen(false); }}
                    options={[
                        {
                            value: LANGUAGES.EN, label: <>
                                <ReactCountryFlag style={{ width: "32px" }} countryCode="GB" svg />
                            </>
                        },
                        {
                            value: LANGUAGES.NL, label: <>
                                <ReactCountryFlag style={{ width: "32px" }} countryCode="NL" svg />
                            </>
                        },
                    ]}
                    value={languageValue}
                    open={menuLanguagesOpen}
                    onClick={() => setMenuLanguagesOpen(!menuLanguagesOpen)}
                />
            </div>
        </div>
    )
}