import React, { useEffect, useState } from 'react';

export default function PageWisePlayground() {
    const [content, setContent] = useState('');

    useEffect(() => {
        const proxyUrl = 'https://corsproxy.io/?key=de8a0203&url=';
        const targetUrl = 'https://denys.hr-technologies.com/front/nl/vacancies/2901?title=administratief-bediende-%282901%29';

        fetch(proxyUrl + targetUrl)
            .then(response => response.text())
            .then(data => {
                // Create a temporary div to load the fetched HTML content
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = data;

                // Set up a MutationObserver to track changes in the DOM
                const observer = new MutationObserver(() => {
                    // When mutations occur, we can process the new content
                    setContent(tempDiv.innerHTML);
                });

                observer.observe(tempDiv, { childList: true, subtree: true });

                // If no content changes within a certain time, stop observing
                setTimeout(() => observer.disconnect(), 5000); // Disconnect after 5 seconds
            })
            .catch(err => console.error('Error:', err));
    }, []); // Empty dependency array ensures this runs once when the component mounts


    return (
        <div>
            <h1>Fetched Content</h1>
            <div>
                {content ? (
                    <pre>{content}</pre> // Displaying fetched content in a preformatted block
                ) : (
                    <p>Loading...</p>
                )}
            </div>
        </div>
    );
}